<template>
  <div class="number_mask_wrapper d-flex justify-content-end">
    <div class="dropdown">
      <button
        class="btn btn-sm search_number_mask_dropdown dropdown-toggle shadow-none"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        v-html="selectedNumberMask"
      ></button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a
          class="dropdown-item"
          v-for="mask in numberMasks"
          :key="mask.value"
          @click="setNumberMask(mask)"
          >{{ mask.label.toUpperCase() }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import NumberMasks from "@/utils/numberMasks";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MaskNumber",
  data: () => ({
    numberMasks: NumberMasks,
    mask: NumberMasks[0],
  }),
  computed: {
    ...mapGetters(["filterParams"]),
    selectedNumberMask: function () {
      return "<span>Вид</span>" + this.mask.label;
    },
  },
  methods: {
    ...mapMutations(["SET_FILTER_MASK"]),
    setNumberMask: function (mask) {
      this.mask = mask;
      this.SET_FILTER_MASK(mask);
      this.$emit("change-mask", mask);
    },
  },
  created() {
    if (this.filterParams.mask) {
      this.mask = this.filterParams.mask;
    }
    this.$emit("change-mask", this.mask);
  },
};
</script>

<style>
.number_mask_wrapper {
  margin-top: 8px;
}
.number_mask_wrapper .dropdown-menu {
  min-width: 142px;
  width: 142px;
  transform: translate3d(0px, 32px, 0px);
}
.search_number_mask_dropdown {
  width: 142px;
  border: 0.5px solid rgba(30, 30, 30, 0.15);
  border-radius: 8px;
  font-weight: normal;
  font-size: 20px;
  color: #1e1e1e;
  padding: 0 0.5rem;
}
.search_number_mask_dropdown::after {
  vertical-align: inherit;
  font-size: 18px;
}
.search_number_mask_dropdown span {
  padding-right: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
</style>