const numberCodes = [
    { label: "Все", value: "-1" },
    { label: "0999", value: "999" },
    { label: "0998", value: "998", is_new: true },
    { label: "0997", value: "997" },
    { label: "0995", value: "995" },
    { label: "0990", value: "990" },

    { label: "0888", value: "888" },
    { label: "0880", value: "880" },
    { label: "0755", value: "755" },
    { label: "0550", value: "550" },
    { label: "0551", value: "551" },
    { label: "0552", value: "552" },
    { label: "0553", value: "553" },
    { label: "0554", value: "554" },
    { label: "0555", value: "555" },
    { label: "0556", value: "556" },
    { label: "0557", value: "557" },
    { label: "0558", value: "558" },
    { label: "0559", value: "559" },
];
export default numberCodes
