<template>
  <div class="platina_modal_wrapper position-relative">
    <!-- <div class="position-absolute platina_modal_img">
      <div class="position-fixed">
        <img :src="classNumberImg" width="88" height="88" />
      </div>
    </div> -->
    <div class="text-center">
      <img :src="classNumberImg" width="88" height="88" />
      <div class="change_m_class_name">{{ classNumberName }}</div>
      <div class="change_m_msisdn mt-4">{{ formattedMsisdn }}</div>
      <div class="change_m_total_sum mt-2">
        {{ info.price }} <span class="currency">с</span>
      </div>
      <div class="change_m_combination_help_text mt-4">
        {{ $t("info.platina_class") }}
      </div>
    </div>
    <div class="cpo_info_wrapper d-flex flex-row align-items-end">
      <div><img src="@/assets/icons/cpo.png" width="32" height="32" /></div>
      <div>
        <a target="_blank" :href="$t('info.cpo_addr_link')">{{
          $t("info.cpo_addr")
        }}</a>
      </div>
    </div>
    <div class="text-center">
      <button
        type="button"
        class="btn btn-accept-filter"
        @click="$modal.hide('PlatinaClassModal')"
      >
        Ок
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
  },
  computed: {
    classNumberName() {
      return this.$t(this.info.category.label_c);
    },
    classNumberImg() {
      return require(`@/assets/icons/${this.info.category.img_alt}`);
      // return require(`@/assets/icons/test.png`);
    },
    formattedMsisdn() {
      return this.info.msisdn
        .replace("996", "0")
        .replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
    },
  },
};
</script>

<style>
.vm--overlay {
  background: rgba(0, 0, 0, 0.5);
}
.platina_modal_wrapper {
  /* padding: 55px 16px 16px; */
  padding: 16px;
}
.platina_modal_wrapper button {
  width: 144px;
}
.platina_modal_img {
  left: 50%;
  top: -35px;
  width: 88px;
  height: 88px;
  margin-left: -44px;
  z-index: 99999;
}
</style>